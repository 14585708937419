.navbar {
	height: 72px;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 28px;
	padding-right: 28px;
	font-family: var(--secondary-font);
	position: relative;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	z-index: 20;
}

.navbar__home {
	height: 72px;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 28px;
	padding-right: 28px;
	font-family: var(--secondary-font);
	position: absolute;
	background-color: transparent;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.568);
	z-index: 20;
}

.opacity__0 {
	opacity: 0;
}

.navbar .navbar__logo {
	height: 100%;
	width: 229px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 7px;
	padding-right: 22px;
}

.navbar .navbar__content {
	height: 100%;
	width: 100%;
}

.navbar__left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 4rem;
}

.navbar__right {
	font-size: 16px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 3rem;
}
.navbar__right-date{
	font-size: 16px !important;
}

.button-hide {
	display: none;
}

.navbar__filter {
	all: unset;
	font-size: 16px;
	letter-spacing: 3px;
	font-family: var(--secondary-font);
	cursor: pointer;
	text-transform: uppercase;
}

.wishlist__button button {
	font-size: 16px;
}

.navbar__right-time {
	all: unset;
	text-align: right;
	font-size: 16px;
	letter-spacing: 3px;
}
@media screen and (max-width: 1100px) {
	.navbar__left {
		gap: 2rem;
	}

	.navbar__right {
		gap: 1rem;
	}
}

@media screen and (max-width: 1068px) {
	.navbar {
		padding-left: 14px;
		padding-right: 14px;
	}

	.navbar__home {
		padding-left: 14px;
		padding-right: 14px;
	}
	.navbar__left {
		gap: 0;
	}

	.navbar__right {
		gap: 0;
		position: relative;
		top: 2px;
	}

	.navbar__right-time {
		display: none;
	}

	.navbar .navbar__logo {
		width: 200px;
	}

	.navbar__logo img {
		width: 180px;
	}
}

.navbar__right-addtowishlist button {
	all: unset;
	text-align: right;
	font-size: 14px;
}

@media screen and (max-width: 734px) {
	.navbar {
		z-index: 20;
	}

	.navbar__home {
		z-index: 20;
	}

	.navbar__right-addtowishlist button {
		all: unset;
		text-align: right;
		font-size: 13px;
	}

	.navbar__right {
		top: 5px;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.navbar .navbar__logo {
		padding-top: 0;
	}

	.wishlist__button {
		padding-top: 3px;
	}

	.navbar__filter {
		padding-bottom: 10px !important;
	}
}

@media only screen and (max-width: 350px) {
	.navbar__right-addtowishlist button {
		font-size: 10px;
	}

	.navbar .navbar__logo {
		width: 140px;
	}

	.navbar .navbar__logo img {
		width: 100%;
	}
}
