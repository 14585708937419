.wishlist__button {
	all: unset;
	cursor: pointer;
	font-size: 16px;
	letter-spacing: 3px;
}

.wishlist__button:hover {
	text-decoration: underline;
}

.wishlist__modal {
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
	background-color: white;
	z-index: 1007;
	font-size: 2rem;
	gap: 1rem;
	padding: 1.5rem;
	-webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

	-webkit-transition-property: visibility, z-index, -webkit-transform;

	transition-property: visibility, z-index, -webkit-transform;

	-o-transition-property: transform, visibility, z-index;

	transition-property: transform, visibility, z-index;

	transition-property: transform, visibility, z-index, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-in-out;
	-o-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-delay: 0s;
	-o-transition-delay: 0s;
	transition-delay: 0s;
	z-index: 210;
}

.wishlist__modal.hidden {
	-webkit-transform: translateY(-250%);
	-ms-transform: translateY(-250%);
	transform: translateY(-250%);
	visibility: hidden;
	-webkit-transition-delay: 0s, 0.5s;
	-o-transition-delay: 0s, 0.5s;
	transition-delay: 0s, 0.5s;
}

.wishlist__modal__cards {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 1rem;
}

.wishlist__empty {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	font-size: 13px;
	font-family: var(--secondary-font);
	width: 100%;
	text-align: center;
	text-transform: initial;
}

.wishlist__compartir {
	all: unset;
	font-size: 16px;
	cursor: pointer;
}

.wishlist-displax-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	z-index: 100;
	gap: 15px;
}

.wishlist__filter-close {
	all: unset;
	position: relative;
	bottom: 2px;
	font-size: 14px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition-property: opacity, visibility;
	-o-transition-property: opacity, visibility;
	transition-property: opacity, visibility;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-in-out;
	-o-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-delay: 0s, 0.5s;
	-o-transition-delay: 0s, 0.5s;
	transition-delay: 0s, 0.5s;
}

.wishlist__filter-close.hide {
	opacity: 0;
	visibility: hidden;
}
@media screen and (max-width: 1068px) {
	.wishlist__modal__cards {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow-x: scroll;
		padding-bottom: 10px;
	}
}

@media screen and (max-width: 768px) {
	.wishlist__button {
		letter-spacing: 0px;
	}
	.wishlist__filter-close {
		display: none;
	}
}
