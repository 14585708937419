.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 1000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: var(--secondary-font);
	letter-spacing: 0.3em;
	font-size: 1.5em;
}

.loading-screen.hidden {
	-webkit-animation: loading-screen-fade-out 0.4s linear forwards;
	animation: loading-screen-fade-out 0.4s linear forwards;
	visibility: hidden;
	-webkit-transition: visibility 0s linear 0.4s;
	-o-transition: visibility 0s linear 0.4s;
	transition: visibility 0s linear 0.4s;
}

.custom-loader {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 8px solid;
	border-color: #000000 #0000;
	-webkit-animation: loading-screen-spinner 1s infinite ease-in-out,
		loading-screen-fade-in 0.4s ease-in-out forwards;
	animation: loading-screen-spinner 1s infinite ease-in-out,
		loading-screen-fade-in 0.4s ease-in-out forwards;
	margin-bottom: 20px;
}

.loading-screen span {
	-webkit-animation: loading-screen-fade-in 0.4s ease-in-out forwards;
	animation: loading-screen-fade-in 0.4s ease-in-out forwards;
}

@-webkit-keyframes loading-screen-spinner {
	to {
		-webkit-transform: rotate(0.5turn);
		transform: rotate(0.5turn);
	}
}

@keyframes loading-screen-spinner {
	to {
		-webkit-transform: rotate(0.5turn);
		transform: rotate(0.5turn);
	}
}

@-webkit-keyframes loading-screen-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes loading-screen-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes loading-screen-fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes loading-screen-fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
