.talents {
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: none;
	width: 100%;
	height: calc(100vh - 72px);
}

.talents::-webkit-scrollbar {
	width: 0;
}

.gallery {
	display: -ms-grid;
	display: grid;
	grid-auto-rows: 22rem;
	grid-template-columns: repeat(auto-fill, minmax(16rem, 2fr));
}

.gallery.filter-open {
	width: calc(100% - 16rem);
}

.talent {
	width: 100%;
	height: 100%;
}

.talent img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.navbar .navbar__filter-container {
	width: 10rem;
	margin-left: auto;
	padding: 0 30px 0 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.navbar .navbar__filter {
	all: unset;
	text-transform: uppercase;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	letter-spacing: 3px;
	font-size: 16px;
	cursor: pointer;
}

.navbar .navbar__filter:hover {
	text-decoration: underline;
}

.navbar .navbar__filter-close {
	all: unset;
	position: relative;
	bottom: 2px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition-property: opacity, visibility;
	-o-transition-property: opacity, visibility;
	transition-property: opacity, visibility;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-in-out;
	-o-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-delay: 0s, 0.5s;
	-o-transition-delay: 0s, 0.5s;
	transition-delay: 0s, 0.5s;
}

.navbar .navbar__filter-close.hide {
	opacity: 0;
	visibility: hidden;
}

.no_filters {
	font-family: var(--secondary-font);
	font-size: 20px;
	width: 100%;
	margin: auto;
	padding-top: 5rem;
	text-align: center;
}

.sidebar_container {
	position: absolute;
	right: 32px;
	top: 72px;
	width: 16rem;
	overflow-y: scroll;
	scrollbar-width: none;
	background-color: white;
	padding: 2rem 2rem 4rem 2rem;
	font-family: var(--secondary-font);
	height: 100%;
	-webkit-transition-property: visibility, -webkit-transform;
	transition-property: visibility, -webkit-transform;
	-o-transition-property: transform, visibility;
	transition-property: transform, visibility;
	transition-property: transform, visibility, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-in-out;
	-o-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-delay: 0s;
	-o-transition-delay: 0s;
	transition-delay: 0s;
	z-index: 10;
}

.sidebar_container::-webkit-scrollbar {
	width: 0;
}

.sidebar_container.hidden {
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
	visibility: hidden;
	-webkit-transition-delay: 0s, 0.5s;
	-o-transition-delay: 0s, 0.5s;
	transition-delay: 0s, 0.5s;
}

.item_container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	gap: 1rem;
	font-size: 13px;
}

.item_container input {
	cursor: pointer;
}

.input_checkbox {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
	width: 18px;
	height: 18px;
	border: 1px solid black;
}

.input_checkbox:checked {
	background-color: black;
	color: black;
}

.input_nombre {
	border: 1px solid black;
	border-radius: 4px;
	font-family: var(--secondary-font);
	padding: 5px;
	outline: none;
}

@media screen and (max-width: 1068px) {
	.gallery {
		display: -ms-grid;
		display: grid;
		grid-auto-rows: 24rem;
	}
}

@media screen and (max-width: 734px) {
	.sidebar_container {
		right: 0;
	}
	.gallery {
		grid-template-columns: repeat(auto-fill, minmax(13rem, 2fr));
		grid-auto-rows: 28rem;
	}
	.navbar .navbar__filter-container {
		padding-left: 10px;
	}

	.navbar .navbar__filter {
		padding-left: 5px;
		letter-spacing: 0px;
	}
	.navbar__filter-close {
		display: none;
	}
}
