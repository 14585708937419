.gallery__item {
	position: relative;
	height: 100%;
	width: 100%;
	-webkit-transition: -webkit-filter 0.5s ease-in-out;
	transition: -webkit-filter 0.5s ease-in-out;
	-o-transition: filter 0.5s ease-in-out;
	transition: filter 0.5s ease-in-out;
	transition: filter 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
	font-family: var(--secondary-font);
	letter-spacing: 0.12rem;
	color: white;
	text-transform: uppercase;
	font-weight: 600;
}

.gallery__item a {
	text-decoration: none;
	color: #ffffff;
}

.gallery__item-info {
	position: absolute;
	bottom: 14px;
	left: 20px;
	right: 20px;
	z-index: 2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.gallery__item-name {
	text-shadow: 0 0 12px black;
}

.gallery__item-wish input,
.gallery__item-wish input:checked {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #ffffff;
	margin: 0;
	border: 1px solid #000000;
	width: 25px;
	height: 25px;
}

.gallery__item-wish input:checked::after {
	content: '*';
	position: absolute;
	font-size: 27px;
	top: 4px;
	right: 7px;
	font-weight: 600;
}
