/* Model Page Navbar */
@media only screen and (max-width: 1068px) {
	.modelpage_scroll .navbar__right-date,
	.modelpage_scroll .navbar__right-time {
		display: none;
	}
}

/* Model Page */
.modelpage_scroll {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	position: relative;
	z-index: 1;
	margin: 0 auto;
}

.modelpage_scroll::-webkit-scrollbar {
	width: 0;
}

.modelpage__profile {
	width: 100%;
	min-height: calc(100vh - 72px);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 1;
}

.modelpage__profile-container {
	width: 100%;
	height: 80vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 4rem;
	padding-left: 3rem;
	padding-right: 3rem;
}

.modelpage__photo {
	height: 100%;
	width: 40%;
}

.modelpage__photo img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.modelpage__data {
	width: 50%;
	margin: auto;
}

.model__name {
	font-size: 160px;
	font-family: var(--primary-font);
	font-weight: 400;
}

.model__data-bottom {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.model__country {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.p-country {
	font-size: 13px;
	font-family: var(--secondary-font);
	letter-spacing: -1px;
	text-transform: uppercase;
	font-weight: 400;
}

.model__country a {
	all: unset;
	font-size: 13px;
	font-family: var(--secondary-font);
	letter-spacing: 1.5px;
	text-transform: uppercase;
	font-weight: 400;
	cursor: pointer;
}

.model__country a:hover {
	text-decoration: underline;
}

.p-medidas {
	font-size: 13px;
	font-family: var(--secondary-font);
	letter-spacing: 1px;
	font-weight: 400;
	gap: 2rem;
}

.p-medidas span {
	display: inline;
}
.modelpage__profile-x {
	all: unset;
	cursor: pointer;
	font-size: 16px;
	font-family: var(--secondary-font);
	position: absolute;
	right: 20px;
	text-decoration: none;
	color: black;
}
.book__title {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-left: 2rem;
	margin-bottom: 4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}
.book__title button:hover {
	text-decoration: underline;
}

.book__title p {
	font-family: var(--terciary-font);
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: none;
	text-rendering: none;
	font-size: 48px;
}

.book__title button {
	all: unset;
	font-family: 13px;
	font-family: var(--secondary-font);
	letter-spacing: 1.5px;
	padding-right: 2rem;
	cursor: pointer;
}

@media screen and (max-width: 1400px) {
	.model__name {
		font-size: 120px;
	}
	.modelpage__profile-container {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

@media screen and (max-width: 1068px) {
	.modelpage__profile {
		min-height: 400px;
	}
	.model__name {
		text-align: center;
	}
	.model__data-bottom {
		text-align: center;
	}
	.model__country {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.modelpage__profile-x {
		right: 15px;
		top: 60px;
		z-index: 999;
	}

	.modelpage__profile-container {
		height: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.modelpage__photo {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
	}

	.modelpage__data {
		width: 100%;
		text-align: left;
		margin: 0 2rem;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.model__data-bottom {
		width: 100%;
	}

	.modelpage__photo {
		width: 70%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		margin: auto;
	}

	.p-medidas span {
		display: inline-block;
		height: 20px;
	}
}

@media screen and (max-width: 734px) {
	.model__name {
		font-size: 70px;
		text-align: start;
		margin-bottom: 2rem;
	}

	.modelpage__profile-container {
		margin-top: 30px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.modelpage__photo {
		width: 100%;
	}

	.modelpage__data {
		margin: 0;
	}
	.polas__title {
		padding-left: 0;
	}
}

/* BOOKS */

.modelpage__second-section {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 4rem;
}

.book__gallery {
	display: -ms-grid;
	display: grid;
	grid-auto-rows: repeat(auto-fill, -webkit-min-content);
	grid-auto-rows: repeat(auto-fill, min-content);
	gap: 2px;
	-ms-grid-columns: minmax(250px, 1fr) 2px minmax(250px, 1fr) 2px
		minmax(250px, 1fr);
	grid-template-columns: repeat(3, minmax(250px, 1fr));
	padding-bottom: 4rem;
	grid-auto-flow: dense;
}

.book__gallery-item {
	width: 100%;
	height: 100%;
}

.book__gallery-item img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.p-book {
	font-family: var(--terciary-font);
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: none;
	text-rendering: none;
	font-size: 48px;
}

@media screen and (max-width: 1200px) {
	.modelpage__second-section {
		padding: 2rem;
	}
	.book__gallery {
		-ms-grid-columns: minmax(250px, 1fr) 1rem minmax(250px, 1fr);
		grid-template-columns: repeat(2, minmax(250px, 1fr));
		gap: 1rem;
		padding-bottom: 1rem;
	}
	.p-polas p {
		font-size: 30px;
	}
}

@media screen and (max-width: 1200px) {
	.p-book {
		font-size: 30px;
	}
}

/* POLAS */

.modelpage__polas {
	margin-bottom: 4rem;
}

.polas__title {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-left: 2rem;
	margin-bottom: 4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

.polas__title p {
	font-family: var(--terciary-font);
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: none;
	text-rendering: none;
	font-size: 48px;
}

.polas__title button {
	all: unset;
	font-family: 13px;
	font-family: var(--secondary-font);
	letter-spacing: 1.5px;
	padding-right: 2rem;
	cursor: pointer;
}
.polas__title button:hover {
	text-decoration: underline;
}

@media screen and (max-width: 734px) {
	.polas__title {
		padding-left: 0;
	}
	.polas__title p {
		font-size: 30px;
	}
	.polas__title button,
	.book__title button {
		font-size: 13px;
		margin-right: 0;
		padding-right: 0;
		letter-spacing: 0px;
	}

	.book__title {
		padding-left: 0;
	}
	.book__title button {
		padding: 0;
		letter-spacing: 0px;
		font-size: 13px;
	}
	.book__title p {
		font-size: 30px;
	}

	.modelpage__profile-x {
		right: 10px;
		top: 50px;
	}

	.modelpage__profile-container,
	.modelpage__second-section {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.book__gallery {
		-ms-grid-columns: 100%;
		grid-template-columns: 100%;
	}
}
