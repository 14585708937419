.background__grey {
	position: absolute;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(40, 40, 40, 0.4);
	z-index: 1000;
}

.compartir__container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: white;
	max-width: 800px;
	min-height: 200px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	max-height: 500px;
	z-index: 2000;
	overflow: hidden;
	padding: 1rem;
}

.compartir_ul-container {
	max-height: 300px;
	width: 100%;
	overflow-y: auto;
}
.model__url {
	font-size: 14px;
	letter-spacing: 0px;
	font-family: var(--secondary-font);
}

.compartir__models-list {
	height: 80%;
	width: 80%;
	margin: auto;
	border-bottom: 1px solid black;
}

.compartir__models-list::-webkit-scrollbar {
	display: none;
}

.compartir__bottom {
	padding: 1rem;
	padding-top: 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: auto;
	width: 80%;
	padding-left: 0;
	padding-right: 0;
}

.model__url {
	padding: 1rem;
	text-decoration: none;
	list-style: none;
	font-size: 13px;
	text-transform: none;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-top: 1px solid black;
}

.compartir__title {
	font-size: 48px;
	width: 100%;
	margin: 1rem;
	font-family: var(--terciary-font);
	font-weight: bold;
	text-transform: none;
	letter-spacing: 0px;
	padding-left: 10%;
	margin-left: 0;
}

.compartir__bottom button {
	all: unset;
	cursor: pointer;
	padding: 0.5rem;
	font-size: 16px;
}
.compartir__bottom button:hover {
	text-decoration: underline;
}
@media only screen and (max-width: 1150px) {
	.compartir__container {
		width: 700px;
	}
}

@media only screen and (max-width: 1000px) {
	.compartir__container {
		width: 600px;
	}
}

@media only screen and (max-width: 734px) {
	.compartir__container {
		width: 100%;
	}
}
