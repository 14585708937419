.polas__gallery-item {
	width: 550px;
	height: 750px;
	background: transparent;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.polas__gallery-item img {
	width: 99%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.forwardBtn {
	background-color: transparent;
	border: none;
}
.backwardBtn {
	background-color: transparent;
	border: none;
}
@media screen and (max-width: 1500px) {
	.polas__gallery-item {
		width: 450px !important;
		height: 620px !important;
	}
}

@media screen and (max-width: 1268px) {
	.polas__gallery-item {
		width: 320px !important;
		height: 500px !important;
	}
}

@media screen and (max-width: 942px) {
	.polas__gallery-item {
		width: 250px !important;
		height: 400px !important;
	}
}

@media screen and (max-width: 820px) {
	.polas__gallery-item {
		width: 200px !important;
		height: 300px !important;
	}
}

@media screen and (max-width: 734px) {
	.polas__gallery-item {
		width: 300px;
		height: 450px;
	}
}
