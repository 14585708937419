.colapse {
	border-bottom: 1px solid rgba(40, 40, 40, 0.5);
	cursor: pointer;
}

.div_container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.filter_content {
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
