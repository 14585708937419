.contactBanner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

.contactBanner_title {
	font-family: var(--primary-font);
	font-size: 2.5rem;
	font-weight: 400;
}

.contactBanner_contact {
	font-family: var(--secondary-font);
	font-size: 14px;
	letter-spacing: 3px;
}

.contactBanner_line {
	width: 100%;
	height: 0.5px;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.6);
	bottom: 8px;
}

.contactBanner a {
	all: unset;
	color: black;
	text-decoration: none;
	cursor: pointer;
	font-family: var(--secondary-font);
	font-size: 14px;
	letter-spacing: 3px;
}

@media screen and (max-width: 768px) {
	.contactBanner a {
		letter-spacing: 0px;
	}
	.contactBanner {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		margin: 0 !important;
	}
}
