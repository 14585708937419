.urban__scouting {
	background-color: transparent;
	overflow: hidden;
	margin-right: 3rem;
}

.urban__scouting span {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	-webkit-animation: urban__scouting 4s linear infinite;
	animation: urban__scouting 4s linear infinite;
	font-size: 16px;
	font-family: var(--secondary-font);
	letter-spacing: 3px;
	top: 2px;
	position: relative;
}

@-webkit-keyframes urban__scouting {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}

	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@keyframes urban__scouting {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}

	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@media screen and (max-width: 1100px) {
	.urban__scouting {
		margin-right: 0;
	}
}

@media only screen and (max-width: 1068px) {
	.urban__scouting span {
		top: 4px;
	}
}

@media only screen and (max-width: 734px) {
	.urban__scouting {
		display: none;
	}
}
