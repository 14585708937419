@import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

/* Visually hidden */
.visuallyhidden {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 99.9% 99.9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
}

/* Custom cursor */

.custom-cursor {
	cursor: url('../public/assets/asteriscofinal.svg') 5 5, auto !important;
}

.custom-cursor-pointer:hover,
.custom-cursor .custom-cursor-pointer:hover {
	cursor: auto;
	cursor: url('../public/assets/hand-cursor.svg') 5 5, auto !important;
}

/* General helpers */
.large-show {
	display: block;
}
.large-hide {
	display: none;
}
.medium-show {
	display: none;
}
.medium-hide {
	display: block;
}
.small-show {
	display: none;
}
.small-hide {
	display: block;
}
@media only screen and (max-width: 1068px) {
	.large-show {
		display: none;
	}
	.large-hide {
		display: block;
	}
	.medium-show {
		display: block;
	}
	.medium-hide {
		display: none;
	}
	.small-show {
		display: none;
	}
	.small-hide {
		display: block;
	}
}

@media only screen and (max-width: 734px) {
	.large-show {
		display: none;
	}
	.large-hide {
		display: block;
	}
	.medium-show {
		display: none;
	}
	.medium-hide {
		display: block;
	}
	.small-show {
		display: block;
	}
	.small-hide {
		display: none;
	}
}

/* Container */
.fluid-container {
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

/* Display None */
.display-none {
	display: none;
}

/* import tipogrfias */

@font-face {
	font-family: 'Helvetica Monospaced';
	src: url('../public/fonts/HelveticaMonospacedW06Rg.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNowDisplay';
	src: url('../public/fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PPMondwest Regular';
	src: url('../public/fonts/PPMondwestRegular.otf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica';
	src: url('../public/fonts/Helvetica.ttc') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/*  variables */

:root {
	--primary-font: 'HelveticaNowDisplay';
	--secondary-font: 'Helvetica Monospaced';
	--terciary-font: 'PPMondwest Regular';
	--home-font: 'Helvetica Neue Medium', sans-serif;
	--logo-font: 'Helvetica', sans-serif;
}
