.pixelated-image {
	width: 100%;
	height: 100%;
	position: relative;
}

.pixelated-image img,
.pixelated-image svg,
.pixelated-image video {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.pixelated-image svg {
	opacity: 0;
	z-index: 2;
	transition: opacity 0.3s ease-in-out;
}

.pixelated-image.hovered svg {
	opacity: 1;
}
