.work {
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: none;
	width: 100%;
	height: calc(100vh - 72px);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-family: var(--secondary-font);
	max-width: 1660px;
	margin: 0 auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	position: relative;
	z-index: 1;
}

.work::-webkit-scrollbar {
	width: 0;
}

.work__sidebar {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-left: 200px;
	font-size: 13px;
	width: 20%;
}

.work__sidebar-title {
	margin-top: 71px;
	margin-bottom: 60px;
	font-size: 48px;
	font-family: var(--terciary-font);
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: none;
	text-rendering: none;
}

.work__sidebar ul {
	text-transform: uppercase;
	list-style: none;
}

.work__sidebar ul li {
	margin-bottom: 18px;
	cursor: pointer;
	letter-spacing: 0.3em;
}

.work__sidebar ul li:hover {
	text-decoration: underline;
}

.work__gallery-container {
	width: 70%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin-top: 145px;
	padding-right: 2rem;
}

.work__gallery {
	width: 100%;
	margin: 30px auto 0;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 100%;
	grid-template-columns: 100%;
	grid-template-rows: repeat(auto-fill, minmax(348px, 1fr));
	gap: 20px;
	opacity: 1;
	visibility: visible;
	-webkit-transition-property: visibility, opacity;
	-o-transition-property: visibility, opacity;
	transition-property: visibility, opacity;
	-webkit-transition-duration: 0s, 0.6s;
	-o-transition-duration: 0s, 0.6s;
	transition-duration: 0s, 0.6s;
	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-delay: 0.6s, 0;
	-o-transition-delay: 0.6s, 0;
	transition-delay: 0.6s, 0;
	margin-bottom: 25px;
}

.work__gallery.hide {
	visibility: hidden;
	overflow-y: hidden;
	height: 0;
	opacity: 0;
	margin-top: 0;
	margin-bottom: 0;
	-webkit-transition-property: visibility, opacity;
	-o-transition-property: visibility, opacity;
	transition-property: visibility, opacity;
	-webkit-transition-duration: 0s, 0.6s;
	-o-transition-duration: 0s, 0.6s;
	transition-duration: 0s, 0.6s;
	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-delay: 1.1s, 0.5s;
	-o-transition-delay: 1.1s, 0.5s;
	transition-delay: 1.1s, 0.5s;
}

.work__togglemenu-title {
	display: none;
}
.position-fixed {
	position: fixed;
}
.position-fixed-mobile {
	position: fixed;
	z-index: 100;
	background-color: white;
	width: 100%;
	top: 50px;
	overflow: hidden;
}

@media only screen and (max-width: 1850px) {
	.work__sidebar {
		margin-left: 40px;
		min-width: 300px;
	}

	.work__gallery-container {
		width: 80%;
	}
}

@media screen and (max-width: 1068px) {
	.work {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow-y: scroll;
	}
	.work__sidebar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-left: 0;
		font-size: 13px;
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}

	.work__gallery-container {
		margin-top: 0;
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.work__sidebar-title,
	.work__sidebar ul {
		display: none;
	}

	.work__togglemenu {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.work__togglemenu-title {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		font-size: 30px;
		font-family: var(--secondary-font);
		padding-left: 20px;
		margin: 20px 0 40px 0;
		gap: 1rem;
	}

	.work__togglemenu ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100%;
	}

	.work__togglemenu {
		padding: 20px 20px 20px 40px;
	}
}

@media screen and (max-width: 1068px) {
	.work__gallery-item .work-description {
		text-align: right;
		font-size: 20px;
		letter-spacing: 0;
	}
}
