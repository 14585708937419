.mobile-menu {
	display: none;
}

@media only screen and (max-width: 734px) {
	.mobile-menu {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 32px;
		z-index: 999;
		background-color: red;
		border-top: 1px solid black;
		font-family: var(--secondary-font);
		letter-spacing: 0.03em;
		text-transform: uppercase;
		overflow-y: hidden;
		-webkit-transition: height 0.5s ease;
		-o-transition: height 0.5s ease;
		transition: height 0.5s ease;
	}

	.mobile-menu.open {
		height: 100%;
		border-top: transparent;
	}

	.mobile-menu__button {
		all: unset;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		height: 32px;
		cursor: pointer;
		text-transform: uppercase;
		background-color: white;
		border-top: 1px solid black;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		z-index: 10;
		bottom: 0;
		position: absolute;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-tap-highlight-color: transparent;
	}

	.mobile-menu__links {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		height: calc(100% - 32px);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: white;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		z-index: 9;
	}

	.mobile-menu__nav-link {
		width: 100%;
		height: 100%;
		border-bottom: 1px solid black;
		color: inherit;
		text-decoration: none;
		-webkit-transform: translateY(100px);
		-ms-transform: translateY(100px);
		transform: translateY(100px);
		-webkit-transition: -webkit-transform 0.8s ease-in-out 0.05s;
		transition: -webkit-transform 0.8s ease-in-out 0.05s;
		-o-transition: transform 0.8s ease-in-out 0.05s;
		transition: transform 0.8s ease-in-out 0.05s;
		transition: transform 0.8s ease-in-out 0.05s,
			-webkit-transform 0.8s ease-in-out 0.05s;
	}

	.mobile-menu__nav-link:nth-child(2) {
		-webkit-transition-delay: 0.1s;
		-o-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}
	.mobile-menu__nav-link:nth-child(3) {
		-webkit-transition-delay: 0.15s;
		-o-transition-delay: 0.15s;
		transition-delay: 0.15s;
	}
	.mobile-menu__nav-link:nth-child(4) {
		-webkit-transition-delay: 0.2s;
		-o-transition-delay: 0.2s;
		transition-delay: 0.2s;
	}

	.mobile-menu.open .mobile-menu__nav-link {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	.mobile-menu__nav-link.dark {
		background-color: black;
		color: white;
	}

	.mobile-menu__nav-link > div {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100vw;
		height: 100%;
		-webkit-transition: height 0.2s cubic-bezier(0.13, 0.77, 0.33, 1);
		-o-transition: height 0.2s cubic-bezier(0.13, 0.77, 0.33, 1);
		transition: height 0.2s cubic-bezier(0.13, 0.77, 0.33, 1);
	}
}
