.animated-layout {
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.animated-item {
	top: 0;
	right: 0;
	position: absolute;
	height: 100vh;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	overflow: hidden;
	background-color: #ffffff;
	outline: 1px solid black;
}

.animated-item.animated {
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.animated-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	width: 32px;
	min-width: 32px;
	height: 100%;
	cursor: pointer;
	text-transform: uppercase;
	background-color: white;
	z-index: 10;
	position: relative;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	font-family: var(--secondary-font);
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.animated-link.dark {
	background-color: black;
	color: white;
}

.animated-link > div {
	height: 202px;
	width: 33px;
	-webkit-transition: height 0.2s ease;
	-o-transition: height 0.2s ease;
	transition: height 0.2s ease;
}

.animated-link span {
	width: 202px;
	display: inline-block;
	-webkit-transform: translateY(100px) translateX(-86px) rotate(90deg);
	-ms-transform: translateY(100px) translateX(-86px) rotate(90deg);
	transform: translateY(100px) translateX(-86px) rotate(90deg);
}

.animated-link:hover > div {
	height: 208px;
}

.animated-nav-link {
	color: inherit;
	text-decoration: none;
	font-family: var(--secondary-font);
	letter-spacing: 0.03em;
}

.animated-content {
	height: 100%;
	width: calc(100vw - 128px);
}

.animated-content.animated {
	-webkit-transition: all 0.5s ease 0.2s;
	-o-transition: all 0.5s ease 0.2s;
	transition: all 0.5s ease 0.2s;
}

@media only screen and (max-width: 734px) {
	.animated-nav-link {
		display: none;
	}
	.animated-content {
		width: 100vw;
		height: calc(100vh - 32px);
	}
}
