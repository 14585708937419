.work__gallery-item {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.work__gallery-item .work-info {
	position: absolute;
	bottom: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0 32px;
	color: #ffffff;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	z-index: 3;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	opacity: 0;
	-webkit-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}

.work__gallery-item:hover .work-info {
	opacity: 1;
}

.work__gallery-item .work-title {
	letter-spacing: -0.06em;
}

.work__gallery-item .work-description {
	text-align: right;
	font-size: 20px;
	letter-spacing: 0.3em;
}
