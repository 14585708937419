.wishlistcard {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	gap: 5px;
	width: 100px;
	height: 120px;

	margin: 1rem;
}

.wishlistcard__left {
	font-size: 12px;
	width: 20%;
	height: 100%;
}

.wishlistcard__left button {
	all: unset;
	cursor: pointer;
	margin: auto;
}

.wishlistcard__image {
	width: 90px;
	height: 127px;
}

.wishlistcard__image img,
.wishlistcard__image video {
	width: 90px;
	height: 127px;
	-o-object-fit: cover;
	object-fit: cover;
}

.wishlistcard__right {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	gap: 5px;
}

.wishlistcard__bot p {
	margin: auto;
}

.wishlistcard__right {
	font-size: 13px;
}
