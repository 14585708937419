.slider {
	position: relative;
	width: 200px;
}

.slider__track,
.slider__range {
	position: absolute;
}
.slider__left-value,
.slider__right-value {
	position: relative;
}
.slider__range__flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.slider__left-value {
	padding-left: 1rem;
}

.slider__right-value {
	padding-right: 1rem;
}
.slider__track,
.slider__range {
	border-radius: 3px;
	height: 2px;
}

.slider__track {
	background-color: rgba(40, 40, 40, 0.5);
	width: 100%;
	z-index: 1;
}

.slider__range {
	background-color: rgba(40, 40, 40, 0.5);
	z-index: 2;
}

.slider__left-value,
.slider__right-value {
	color: #0d0d0e;
	font-size: 12px;
	margin-top: 20px;
}

.slider__left-value {
	left: 6px;
}

.slider__right-value {
	right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}

.thumb {
	pointer-events: none;
	position: absolute;
	height: 0;
	width: 200px;
	outline: none;
}

.thumb--left {
	z-index: 3;
}

.thumb--right {
	z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
	background-color: rgb(40, 40, 40);
	border: none;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 1px 1px rgb(40, 40, 40);
	box-shadow: 0 0 1px 1px rgb(40, 40, 40);
	cursor: pointer;
	height: 10px;
	width: 10px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
	background-color: rgb(40, 40, 40);
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px rgb(40, 40, 40);
	cursor: pointer;
	height: 10px;
	width: 10px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* Hide for collapse animation */
.filter_content .thumb {
	opacity: 0;
	display: none;
	-webkit-transition-property: display, opacity;
	-o-transition-property: display, opacity;
	transition-property: display, opacity;
	-webkit-transition-duration: 0.3s, 0.6s;
	-o-transition-duration: 0.3s, 0.6s;
	transition-duration: 0.3s, 0.6s;
	-webkit-transition-timing-function: ease-in-out;
	-o-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-delay: 0.6s, 0;
	-o-transition-delay: 0.6s, 0;
	transition-delay: 0.6s, 0;
	cursor: default;
}

.filter_content.show .thumb {
	opacity: 1;
	display: block;
}
