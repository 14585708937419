.home {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	position: relative;
}

.home::-webkit-scrollbar {
	display: none;
	width: 0;
	scrollbar-width: none;
}

.home__video {
	width: 100%;
	height: 100vh;
}

.home__video video {
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	position: relative;
}

.home__video-title {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 10;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: var(--logo-font);
	font-weight: 400;
	font-size: 180px;
}

.home__about {
	width: 100%;
	min-height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 2rem 4rem 2rem 4rem;
}

.home__about p {
	font-size: 40px;
	font-family: var(--home-font);
	margin: auto;
	font-size: 55px;
	font-weight: 200;
}

.about__underline {
	border-bottom: 2px solid grey;
}

.home__about p a {
	text-decoration: none;
	color: black;
	font-size: 55px;
	cursor: pointer;
}

@media screen and (max-width: 1568px) {
	.home__about p {
		font-size: 40px;
	}
	.home__about p a {
		font-size: 40px;
	}
	.home__about p img {
		width: 35px;
		height: 35px;
		padding-top: 5px;
	}
}

@media screen and (max-width: 1368px) {
	.home__about p {
		font-size: 35px;
	}
	.home__about p a {
		font-size: 35px;
	}
	.home__about p img {
		width: 30px;
		height: 30px;
		padding-top: 5px;
	}
	.home__video-title {
		font-size: 150px;
	}
}

@media screen and (max-width: 1134px) {
	.home__video-title {
		font-size: 120px;
	}
}

@media screen and (max-width: 734px) {
	.home {
		z-index: 5;
	}
	.home__about {
		padding: 2rem;
	}
	.home__about p {
		font-size: 25px;
	}
	.home__about p a {
		font-size: 25px;
	}

	.home__about p img {
		width: 18px;
		height: 18px;
		padding-top: 5px;
	}

	.home__video-title {
		font-size: 70px;
	}
}

@media screen and (max-width: 390px) {
	.home__about p {
		font-size: 24px;
	}
	.home__about p a {
		font-size: 24px;
	}

	.home__about p img {
		width: 18px;
		height: 18px;
		padding-top: 5px;
	}

	.home__video-title {
		font-size: 50px;
	}
}
