.contact_container {
	width: 100%;
	height: 85%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 5rem;
}

.contact_container-photo {
	width: 40%;
	min-width: 300px;
	height: 100%;
}

.contact_container-photo-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.contact_container-photo-container img {
	width: auto;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.contact_container-info {
	width: 100%;
	margin-left: 5rem;
	padding-left: 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.contact_container-info-title {
	font-size: 9rem;
	font-family: var(--primary-font);
	font-weight: 400;
}

.contact_container-info-bsas {
	font-family: var(--secondary-font);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 1rem;
}

.contact_container-info-tel {
	font-family: var(--secondary-font);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 3px;
}

.contact_container-info-direc {
	font-family: var(--secondary-font);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 0.5rem;
}
.contact_container-info-instagram {
	text-decoration: none;
	color: black;
	font-family: var(--secondary-font);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 3px;
}
.contact_container-info-instagram:hover {
	text-decoration: underline;
}
.contact_container-info-two {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	gap: 1rem;
}

.contact_footer-desc {
	font-family: var(--secondary-font);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: -1px;
}

.contact_footer-note {
	font-family: var(--secondary-font);
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 3px;
}

@media screen and (max-width: 1600px) {
	/* .contact_container {
		padding: 4rem;
	} */
	.contact_container-info{
		margin-left: 4rem;
	}
}


@media screen and (max-width: 1500px) {
	.contact_container-photo {
		width: 50%;
	}
	.contact_container-info{
		margin-left: 3rem;
	}
}

/* IPAD */

@media screen and (max-width: 1068px) {
	.contact_container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 5rem 2rem;
		padding-top: 0;
		padding-bottom: 0;
		overflow-y: scroll;
	}

	.contact_container-photo {
		width: 100%;
		height: 40%;
		max-height: 400px;
	}

	.contact_container-info {
		margin-left: 0;
		padding-left: 0;
	}

	.contact_container-info-title {
		font-size: 6rem;
		margin-bottom: 1rem;
	}

	.contact_container-info-two {
		margin-top: 2rem;
		margin-bottom: 4rem;
	}

	.contact_container-photo {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
		width: 40%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		margin: auto;
	}
}

@media screen and (max-width: 734px) {
	.contact_container {
		padding-left: 0;
		padding-right: 0;
	}

	.contact_container-info {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.contact_container-info-title {
		margin-top: 2rem;
		margin-bottom: 2rem;
		font-size: 60px;
	}

	.contact_container-info-one {
		margin-bottom: 2rem;
	}

	.contactBanner {
		margin-top: 3rem;
		margin-bottom: 3rem;
		font-size: 24px;
	}

	.contactBanner_contact {
		font-size: 13px;
		letter-spacing: 1px;
	}

	.contactBanner_line {
		display: none;
	}
}
