.page-404 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-color: #f5f5f5;
}

.page-404 h1 {
	font-size: 138px;
	font-family: var(--primary-font);
}

.page-404 h2,
.page-404 a {
	font-size: 13px;
	font-family: var(--secondary-font);
	letter-spacing: 1.5px;
	text-transform: uppercase;
	font-weight: 400;
	margin-top: 1rem;
}

.page-404 a {
	color: #000;
	cursor: pointer;
	text-decoration: underline;
	margin-top: 20px;
}
